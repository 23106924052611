<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入姓名/手机号/学校名称" style="width: 280px;" class="filter-item" clearable/>

      <el-select class="filter-item" v-model="listQuery.is_deal" placeholder="处理状态" style="width: 140px" clearable>
        <el-option label="未处理" :value="0"></el-option>
        <el-option label="已处理" :value="1"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="手机号码" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.phone }}
        </template>
      </el-table-column>
      <el-table-column label="负责学校名称" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="申请理由" min-width="160" align="center">
        <template slot-scope="scope">
          {{ scope.row.suggest }}
        </template>
      </el-table-column>

      <el-table-column label="图片" min-width="160" align="center">
        <template slot-scope="scope">
          <viewer :images="scope.row.imgs">
            <img v-for="(src,index) in scope.row.imgs" :src="src" :key="index" style="margin-right: 10px; width: 100px; height: 100px; object-fit: contain; border: 1px solid #ccc; border-radius: 5px; cursor: pointer;">
          </viewer>
        </template>
      </el-table-column>

      <el-table-column label="是否处理" min-width="60" align="center">
        <template slot-scope="scope">
          {{ scope.row.is_deal == 1 ? '已处理' : '未处理' }}
        </template>
      </el-table-column>
      <el-table-column label="申请时间" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="120" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button v-if="scope.row.is_deal != 1" type="primary" size="mini" @click="handleDeal(scope.row)">处理</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        is_deal: "",
      },
      dialogVisible: false,
      btnLoading: false,
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/feedback/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleDeal(item) {
      this.$confirm("确定标为已处理吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/feedback/deal",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.floor-container {
  display: inline-block;
  width: 160px;
  height: 160px;
  margin-right: 25px;
  margin-bottom: 25px;
}
</style>
